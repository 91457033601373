.job_desc {
    text-align: justify;
}

.job_desc>ol {
    list-style-type: decimal;
}


.job_desc>h1 {
    font-size: 3.5rem;
}

.job_desc>h2 {
    font-size: 2.5rem;
}

.job_desc>h3 {
    font-size: 1.5rem;
}

.job_desc>h4 {
    font-size: 1rem;
}

.job_desc>ul {
    list-style-type: disc;
}

.job_desc>ol {
    list-style-type: decimal;
}